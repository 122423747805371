.search {
  position: relative;
}

.search__input {
  inline-size: 230px;
  position: relative;
  padding-block: var(--space-2);
  padding-inline: var(--space-5) var(--space-7);
  border: none;
  background-color: var(--color-neutral-bg);
  color: var(--color-neutral-text-contrast);

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  &:hover {
    background-color: var(--color-neutral-bg-hover);
  }

  &:focus-visible {
    outline: var(--color-primary-focus-ring) solid 2px;
  }

  &::placeholder {
    color: var(--color-neutral-placeholder);
  }

  .search[data-mode="overlay"] & {
    border-radius: var(--rounded-full);
  }

  .search[data-mode="inline"] & {
    inline-size: 100%;
    display: block;
    padding-block: var(--space-3);
    padding-inline: var(--space-4);
    border-radius: var(--rounded-md);
  }
}

.search__kbd {
  position: absolute;
  inset-block-start: calc(50% - 1px);
  inset-inline-end: var(--space-4);
  transform: translateY(-50%);
  pointer-events: none;

  input:hover + &,
  input:focus + & {
    transform: translateY(calc(1px - 50%));
  }
}

.search__popover {
  .search[data-mode="overlay"] & {
    inline-size: 400px;
    max-block-size: 300px;
    position: absolute;
    z-index: 1;
    inset-block-start: calc(100% + 4px);
    inset-inline-end: 0;
    box-shadow: var(--shadow-lg);
    border-radius: var(--rounded-lg);
    background-color: var(--color-surface);
    overflow: auto;
  }

  .search[data-mode="inline"] & {
    margin-block-start: var(--space-6);
  }
}

.search__info {
  color: var(--color-neutral-text);

  .search[data-mode="overlay"] & {
    padding-inline: var(--space-4);
    padding-block: var(--space-3);
    font-size: var(--font-size-sm);
  }
}

.search__results {
  .search[data-mode="inline"] & li + li {
    margin-block-start: var(--space-2);
  }
}

.search__result {
  display: grid;
  grid-template-columns: minmax(0, 1fr) auto;
  gap: var(--space-1) var(--space-4);
  padding: var(--space-4);
  color: var(--color-primary-text);
  text-decoration: none;

  .search__popover:hover & {
    transition: all 200ms ease;
  }

  &:hover {
    background-color: var(--color-primary-bg-subtle);
  }

  &[aria-selected="true"] {
    background-color: var(--color-primary-bg-subtle);
  }

  .search[data-mode="inline"] & {
    border-radius: var(--rounded-lg);
  }

  .search[data-mode="overlay"] & {
    &:not([data-first="true"]) {
      border-block-start: 1px solid var(--color-primary-line-weak);
    }
  }
}

.search__note-title {
  font-weight: var(--font-weight-medium);
}

.search__note-content {
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);

  mark {
    background: var(--color-primary-mark);
    color: inherit;
  }
}

.search__selected-icon {
  grid-area: 1 / 2 / span 2;
  align-self: end;
  opacity: 0;

  [aria-selected="true"] & {
    opacity: 1;
  }
}
