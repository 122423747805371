code[class*="language-"], pre[class*="language-"] {
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  color: #90a4ae;
  tab-size: 4;
  -webkit-hyphens: none;
  hyphens: none;
  background: #fafafa;
  font-family: Roboto Mono, monospace;
  font-size: 1em;
  line-height: 1.5em;
}

code[class*="language-"]::-moz-selection {
  color: #263238;
  background: #cceae7;
}

pre[class*="language-"]::-moz-selection {
  color: #263238;
  background: #cceae7;
}

code[class*="language-"] ::-moz-selection {
  color: #263238;
  background: #cceae7;
}

pre[class*="language-"] ::-moz-selection {
  color: #263238;
  background: #cceae7;
}

code[class*="language-"]::selection, pre[class*="language-"]::selection, code[class*="language-"] ::selection, pre[class*="language-"] ::selection {
  color: #263238;
  background: #cceae7;
}

:not(pre) > code[class*="language-"] {
  white-space: normal;
  border-radius: .2em;
  padding: .1em;
}

pre[class*="language-"] {
  margin: .5em 0;
  padding: 1.25em 1em;
  position: relative;
  overflow: auto;
}

.language-css > code, .language-sass > code, .language-scss > code {
  color: #f76d47;
}

[class*="language-"] .namespace {
  opacity: .7;
}

.token.atrule {
  color: #7c4dff;
}

.token.attr-name {
  color: #39adb5;
}

.token.attr-value, .token.attribute {
  color: #f6a434;
}

.token.boolean {
  color: #7c4dff;
}

.token.builtin, .token.cdata, .token.char, .token.class {
  color: #39adb5;
}

.token.class-name {
  color: #6182b8;
}

.token.comment {
  color: #aabfc9;
}

.token.constant {
  color: #7c4dff;
}

.token.deleted {
  color: #e53935;
}

.token.doctype {
  color: #aabfc9;
}

.token.entity {
  color: #e53935;
}

.token.function {
  color: #7c4dff;
}

.token.hexcode {
  color: #f76d47;
}

.token.id, .token.important {
  color: #7c4dff;
  font-weight: bold;
}

.token.inserted {
  color: #39adb5;
}

.token.keyword {
  color: #7c4dff;
}

.token.number {
  color: #f76d47;
}

.token.operator {
  color: #39adb5;
}

.token.prolog {
  color: #aabfc9;
}

.token.property {
  color: #39adb5;
}

.token.pseudo-class, .token.pseudo-element {
  color: #f6a434;
}

.token.punctuation {
  color: #39adb5;
}

.token.regex {
  color: #6182b8;
}

.token.selector {
  color: #e53935;
}

.token.string {
  color: #f6a434;
}

.token.symbol {
  color: #7c4dff;
}

.token.tag {
  color: #e53935;
}

.token.unit {
  color: #f76d47;
}

.token.url, .token.variable {
  color: #e53935;
}

:root {
  --font-family-default: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-size-xs: .75rem;
  --font-size-sm: .875rem;
  --font-size-fluid-x2: clamp(.79rem, calc(.76rem + .17vw), .89rem);
  --font-size-fluid-x1: clamp(.89rem, calc(.85rem + .19vw), 1rem);
  --font-size-fluid-0: clamp(1rem, calc(.96rem + .22vw), 1.13rem);
  --font-size-fluid-1: clamp(1.13rem, calc(1.08rem + .24vw), 1.27rem);
  --font-size-fluid-2: clamp(1.27rem, calc(1.21rem + .28vw), 1.42rem);
  --font-size-fluid-3: clamp(1.42rem, calc(1.36rem + .31vw), 1.6rem);
  --font-size-fluid-4: clamp(1.6rem, calc(1.53rem + .35vw), 1.8rem);
  --font-size-fluid-5: clamp(1.8rem, calc(1.72rem + .39vw), 2.03rem);
  --font-size-fluid-6: clamp(2.03rem, calc(1.94rem + .44vw), 2.28rem);
  --font-size-fluid-7: clamp(2.28rem, calc(2.18rem + .5vw), 2.57rem);
  --font-size-fluid-8: clamp(2.57rem, calc(2.45rem + .56vw), 2.89rem);
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --rounded-sm: 2px;
  --rounded-md: 4px;
  --rounded-lg: 8px;
  --rounded-full: 9999px;
  --shadow-xs: 0 1px 3px #64646417;
  --shadow-sm: 0 1px 5px #6464640d;
  --shadow-md: 0 0 0 1px var(--color-neutral-border), 0 1px 5px #6464640d, 0 0 40px #64646404;
  --shadow-lg: 0 0 0 1px var(--color-neutral-border), 0 5px 17px #64646424;
  --shadow-xl: 0 4px 12px #64646426, 0 0 0 1px #0000000d;
  --shadow-xxl: 0 24px 38px 3px #64646429, 0 9px 86px 8px #6464641a, 0 11px 15px -7px #6464641a, 0 0 0 1px #0000000d;
  --space-0: 0px;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 24px;
  --space-6: 36px;
  --space-7: 48px;
  --space-8: 64px;
  --color-backdrop: var(--light, #00000040) var(--dark, #00000080);
  --color-background: var(--light, #fff) var(--dark, #111113);
  --color-surface: var(--light, #fff) var(--dark, #111113);
  --color-primary-mark: var(--light, #000eff25) var(--dark, #5b62fd6b);
  --color-primary-bg-subtle: var(--light, #f8f8ff) var(--dark, #171625);
  --color-primary-bg: var(--light, #f0f1fe) var(--dark, #202248);
  --color-primary-bg-hover: var(--light, #e6e7ff) var(--dark, #262a65);
  --color-primary-bg-active: var(--light, #dadcff) var(--dark, #303374);
  --color-primary-line-weak: var(--light, #dadcff) var(--dark, #303374);
  --color-primary-line: var(--light, #cbcdff) var(--dark, #3d3e82);
  --color-primary-border: var(--light, #b8baf8) var(--dark, #4a4a95);
  --color-primary-border-hover: var(--light, #9b9ef0) var(--dark, #5958b1);
  --color-primary-focus-ring: var(--light, #9b9ef0) var(--dark, #5958b1);
  --color-primary-bg-solid: var(--light, #5b5bd6) var(--dark, #5b5bd6);
  --color-primary-bg-solid-hover: var(--light, #5151cd) var(--dark, #6e6ade);
  --color-primary-text: var(--light, #5753c6) var(--dark, #b1a9ff);
  --color-primary-text-contrast: var(--light, #272962) var(--dark, #e0dffe);
  --color-primary-placeholder: var(--light, #5b5bd6) var(--dark, #5b5bd6);
  --color-neutral-bg-subtle: var(--light, #f9f9fb) var(--dark, #18191b);
  --color-neutral-bg: var(--light, #f0f0f3) var(--dark, #212225);
  --color-neutral-bg-hover: var(--light, #e8e8ec) var(--dark, #272a2d);
  --color-neutral-bg-active: var(--light, #e0e1e6) var(--dark, #2e3135);
  --color-neutral-line-weak: var(--light, #e0e1e6) var(--dark, #2e3135);
  --color-neutral-line: var(--light, #d9d9e0) var(--dark, #363a3f);
  --color-neutral-border: var(--light, #cdced6) var(--dark, #43484e);
  --color-neutral-border-hover: var(--light, #b9bbc6) var(--dark, #5a6169);
  --color-neutral-focus-ring: var(--light, #b9bbc6) var(--dark, #5a6169);
  --color-neutral-bg-solid: var(--light, #8b8d98) var(--dark, #696e77);
  --color-neutral-bg-solid-hover: var(--light, #80838d) var(--dark, #777b84);
  --color-neutral-text: var(--light, #60646c) var(--dark, #b0b4ba);
  --color-neutral-text-contrast: var(--light, #1c2024) var(--dark, #edeef0);
  --color-neutral-placeholder: var(--light, #8b8d98) var(--dark, #696e77);
  --callout-blue-text: var(--light, #006dcbf2) var(--dark, #70b8ff);
  --callout-blue-bg: var(--light, #008ff519) var(--dark, #0077ff3a);
  --callout-mint-text: var(--light, #007763fd) var(--dark, #67ffded2);
  --callout-mint-bg: var(--light, #00d29e22) var(--dark, #00fff61d);
  --callout-green-text: var(--light, #00713fde) var(--dark, #46fea5d4);
  --callout-green-bg: var(--light, #00a43319) var(--dark, #22ff991e);
  --callout-orange-text: var(--light, #cc4e00) var(--dark, #ffa057);
  --callout-orange-bg: var(--light, #ff9c0029) var(--dark, #fb6a0025);
  --callout-red-text: var(--light, #c40006d3) var(--dark, #ff9592);
  --callout-red-bg: var(--light, #f3000d14) var(--dark, #ff173f2d);
  --callout-purple-text: var(--light, #52009aba) var(--dark, #d19dff);
  --callout-purple-bg: var(--light, #8e00f112) var(--dark, #c150ff2d);
  --callout-gray-text: var(--light, #0000009b) var(--dark, #ffffffaf);
  --callout-gray-bg: var(--light, #00000006) var(--dark, #ffffff09);
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

body {
  background-color: var(--color-background);
  color: var(--color-neutral-text-contrast);
  font-family: var(--font-family-default);
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
  scroll-margin-block-start: 6rem;
}

small {
  color: var(--color-neutral-text);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-fluid-x1);
}

ul[role] {
  margin: 0;
  padding: 0;
  list-style: none;
}

hr {
  background-color: var(--color-neutral-border);
  border: none;
  block-size: 1px;
  margin: 0;
}

@media (prefers-color-scheme: dark) {
  :root {
    color-scheme: dark;
    --light: ;
    --dark: initial;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    color-scheme: light;
    --light: initial;
    --dark: ;
  }
}

:root[data-theme="dark"] {
  color-scheme: dark;
  --light: ;
  --dark: initial;
}

:root[data-theme="light"] {
  color-scheme: light;
  --light: initial;
  --dark: ;
}

[data-no-transition], [data-no-transition] *, [data-no-transition] :before, [data-no-transition] :after {
  transition: none !important;
}

kbd {
  padding-block: var(--space-1);
  padding-inline: var(--space-2);
  box-shadow: 0 2px 0 1px var(--color-neutral-border);
  border-radius: var(--rounded-md);
  border: 1px solid var(--color-neutral-border);
  background-color: var(--color-surface);
  font-family: var(--font-family-mono);
  cursor: default;
  font-size: .8em;
  line-height: 1;
  display: inline-block;
}

kbd:hover, [data-kbd-trigger]:hover + kbd, [data-kbd-trigger]:focus + kbd {
  box-shadow: 0 1px 0 .5px var(--color-neutral-border);
  transform: translateY(1px);
}

.code-block {
  position: relative;
}

.code-block__copy {
  z-index: 1;
  padding: var(--space-2);
  border-radius: var(--rounded-md);
  background: var(--color-neutral-bg-subtle);
  color: var(--color-neutral-text);
  cursor: pointer;
  border: none;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  display: flex;
  position: absolute;
  inset-block-start: var(--space-2);
  inset-inline-end: var(--space-2);
}

.code-block:not(:hover) .code-block__copy:not(:focus) {
  opacity: 0;
}

.code-block__copy:hover, .code-block__copy:focus {
  background-color: var(--color-neutral-bg);
}

.code-block__copy svg {
  block-size: 1.125em;
  inline-size: 1.125em;
}

.icon-btn {
  padding: var(--space-2);
  border-radius: var(--rounded-full);
  color: var(--color-primary-text);
  cursor: pointer;
  background: none;
  border: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: 0;
  transition: all .3s;
  display: flex;
}

.icon-btn:hover, .icon-btn:focus-visible {
  background-color: var(--color-neutral-bg-hover);
}

.icon-btn:active {
  background-color: var(--color-neutral-bg-active);
}

.note-link {
  color: var(--color-neutral-text);
  font-size: var(--font-size-fluid-0);
  text-decoration: none;
}

.note-link:hover {
  color: var(--color-primary-text);
  text-decoration: underline;
}

.toggle-btn {
  justify-content: center;
  align-items: center;
  gap: var(--space-2);
  padding-block: var(--space-1);
  padding-inline: var(--space-2);
  border-radius: var(--rounded-md);
  cursor: pointer;
  font-size: var(--font-size-sm);
  color: var(--color-primary-text);
  background: none;
  border: 0;
  text-decoration: none;
  transition: all .3s;
  display: flex;
}

.toggle-btn:hover {
  background-color: var(--color-primary-bg-hover);
}

.toggle-btn[aria-pressed="true"] {
  background-color: var(--color-primary-bg-active);
}

.toggle-btn[aria-pressed="true"] svg {
  fill: currentColor;
}

.app-header {
  z-index: 3;
  align-items: center;
  gap: var(--space-4);
  padding-block: var(--space-2);
  padding-inline: var(--space-4);
  background-color: var(--color-surface);
  align-items: center;
  block-size: 4rem;
  transition: box-shadow .2s ease-in-out;
  display: flex;
  position: sticky;
  inset-block-start: 0;
}

.app-header[data-scrolled="true"] {
  box-shadow: var(--shadow-lg);
}

:root:not(.supports-js) .app-header {
  border-block-end: 1px solid var(--color-neutral-line);
}

.app-header > :not(.app-header__title) {
  flex-shrink: 0;
}

.app-header__title {
  margin-inline: var(--space-2) auto;
  outline-offset: var(--space-2);
  color: var(--color-neutral-text-contrast);
  font-weight: var(--font-weight-bold);
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
  overflow: hidden;
}

.app-header__title:hover {
  color: var(--color-primary-text);
}

.app-layout__sidebar {
  background-color: var(--color-backdrop);
  overscroll-behavior: contain;
  z-index: 2;
}

.app-layout__sidebar[data-open="false"] {
  display: none;
}

.supports-js .app-layout__sidebar {
  position: fixed;
  inset: 4rem 0 0;
  overflow: auto;
}

.app-layout__sidebar-inner {
  padding-block: var(--space-4);
  background-color: var(--color-surface);
  min-block-size: 100%;
  inline-size: 100%;
}

:root.supports-js .app-layout__sidebar-inner {
  box-shadow: var(--shadow-xxl);
  max-inline-size: 400px;
}

@media (width >= 75em) {
  .app-layout__wrapper {
    grid-template-columns: 300px minmax(0, 1fr);
    display: grid;
  }

  .app-layout__sidebar {
    block-size: calc(100vh - 4rem);
    inset: 4rem 0 0;
    overflow: auto;
    display: block !important;
    position: sticky !important;
  }
}

.page__main {
  padding-inline: var(--space-4);
  padding-block: var(--space-6);
  inline-size: 72ch;
  max-inline-size: 100%;
  margin-inline: auto;
  scroll-margin-block-start: 6rem;
}

.page__panel {
  padding-block: var(--space-6);
  padding-inline: var(--space-4);
  overscroll-behavior: contain;
}

.page__title {
  font-size: var(--font-size-fluid-6);
  margin-block-end: var(--space-6);
  line-height: 1.3;
}

.page__description {
  font-size: var(--font-size-fluid-1);
  margin-block-end: var(--space-6);
  line-height: 1.3;
}

@media (width >= 64em) {
  .page {
    grid-template-columns: minmax(0, 1fr) 300px;
    display: grid;
  }

  .page__panel {
    z-index: 1;
    block-size: calc(100vh - 4rem);
    position: sticky;
    inset: 0;
    inset-block-start: 4rem;
    overflow: auto;
  }
}

.callout {
  border-radius: var(--rounded-md);
  background: var(--callout-bg);
}

.callout[data-color="blue"] {
  --callout-text: var(--callout-blue-text);
  --callout-bg: var(--callout-blue-bg);
}

.callout[data-color="mint"] {
  --callout-text: var(--callout-mint-text);
  --callout-bg: var(--callout-mint-bg);
}

.callout[data-color="green"] {
  --callout-text: var(--callout-green-text);
  --callout-bg: var(--callout-green-bg);
}

.callout[data-color="orange"] {
  --callout-text: var(--callout-orange-text);
  --callout-bg: var(--callout-orange-bg);
}

.callout[data-color="red"] {
  --callout-text: var(--callout-red-text);
  --callout-bg: var(--callout-red-bg);
}

.callout[data-color="purple"] {
  --callout-text: var(--callout-purple-text);
  --callout-bg: var(--callout-purple-bg);
}

.callout[data-color="gray"] {
  --callout-text: var(--callout-gray-text);
  --callout-bg: var(--callout-gray-bg);
}

.callout__title {
  align-items: center;
  gap: var(--space-2);
  padding-block: var(--space-3);
  padding-inline: var(--space-4);
  font-weight: var(--font-weight-bold);
  color: var(--callout-text);
  display: flex;
}

summary.callout__title {
  cursor: pointer;
  margin: 0;
  list-style: none;
}

summary.callout__title::-webkit-details-marker {
  display: none;
}

.callout__icon {
  flex-shrink: 0;
}

.callout__icon svg {
  stroke-width: 1.5px;
  block-size: 1.5em;
  inline-size: 1.5em;
}

.callout__foldable {
  flex-shrink: 0;
  margin-inline-start: auto;
}

details[open] > summary > .callout__foldable {
  transform: rotate(180deg);
}

.callout__content {
  padding: var(--space-4);
  padding-block-start: 0;
}

.custom-props__label {
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  margin: 0;
  margin-block-end: var(--space-2);
}

.custom-props__list dt {
  color: var(--color-neutral-text);
  font-size: var(--font-size-xs);
}

.custom-props__list dd {
  color: var(--color-neutral-text-contrast);
  font-size: var(--font-size-sm);
}

.custom-props__value {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-props__links {
  color: inherit;
  text-underline-offset: 5px;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
}

.custom-props__links:hover {
  text-decoration-style: solid;
}

.article > * + * {
  margin-block-start: var(--flow-space, 1.5em);
  margin-block-end: 0;
}

.article h1 + *, .article h2 + *, .article h3 + *, .article h4 + *, .article h5 + *, .article h6 + *, .article p + ul, .article p + ol {
  --flow-space: .75em;
}

.article pre {
  margin-block: 0;
}

.article p {
  line-height: 1.6;
}

.article ul {
  list-style-type: disc;
}

.article ul, .article ol {
  padding-inline-start: var(--space-4);
}

.article ul ul, .article ul ol, .article ol ul, .article ol ol {
  margin-block-start: .3em;
}

.article ul[data-link-list] a:not(:hover), .article ol[data-link-list] a:not(:hover) {
  text-decoration: none;
}

.article li + li {
  margin-block-start: .3em;
}

.article h1 {
  font-size: var(--font-size-fluid-4);
}

.article h2 {
  font-size: var(--font-size-fluid-3);
}

.article h3 {
  font-size: var(--font-size-fluid-2);
}

.article h4 {
  font-size: var(--font-size-fluid-1);
}

.article h5 {
  font-size: var(--font-size-fluid-0);
}

.article h6 {
  font-size: var(--font-size-fluid-x2);
  text-transform: uppercase;
  letter-spacing: .01em;
}

.article h1, .article h2, .article h3, .article h4, .article h5, .article h6 {
  line-height: 1.25;
}

.article a {
  color: var(--color-primary-text);
  text-underline-offset: 5px;
  text-decoration-style: dashed;
  text-decoration-thickness: 1px;
}

.article a:hover {
  text-decoration-style: solid;
}

.article table {
  border-block-start: 1px solid var(--color-neutral-border);
  border-collapse: collapse;
  inline-size: 100%;
}

.article table td, .article table th {
  border-bottom: 1px solid var(--color-neutral-border);
  padding-inline: var(--space-1);
}

.article table td {
  padding-block: var(--space-3);
}

.article table th {
  padding-block: var(--space-2);
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  letter-spacing: .01em;
}

.article blockquote {
  border-inline-start: 3px solid var(--color-neutral-line);
  padding-inline-start: var(--space-4);
}

.article blockquote p {
  color: var(--color-neutral-text);
  font-style: italic;
}

.article pre, .article code {
  font-family: var(--font-family-mono);
  line-height: 1.5;
}

.article pre {
  direction: ltr;
  text-align: start;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  tab-size: 2;
  -webkit-hyphens: none;
  hyphens: none;
  border-radius: var(--rounded-lg);
  background-color: var(--color-neutral-bg-subtle);
  padding: 1em;
  font-size: 14px;
  line-height: 1.375;
  overflow: auto;
  box-shadow: 1px 1px #0000000d;
}

.article code {
  border-radius: var(--rounded-md);
  background-color: var(--color-neutral-bg);
  padding: .2em .3em;
  font-size: .9em;
}

.article pre code {
  font-size: inherit;
  word-break: break-all;
  padding: 0;
  background-color: #0000 !important;
}

.article .footnotes-sep {
  border-style: solid;
  border-color: var(--color-neutral-line);
}

.article .anchor-link {
  outline-offset: 4px;
  color: #0000;
  margin-inline-start: .1em;
  text-decoration: none;
  transition: color .3s;
}

.article .anchor-link svg {
  display: inline-block;
  transform: translateY(1px);
}

.article :hover > .anchor-link, .article .anchor-link:focus {
  color: var(--color-neutral-text);
}

.grouped-links__label {
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  margin: 0;
  margin-block-end: var(--space-2);
}

.grouped-links__link {
  padding-block: var(--space-1);
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
  align-items: center;
  max-inline-size: 100%;
  text-decoration: none;
  display: inline-flex;
}

.grouped-links__link svg {
  box-sizing: content-box;
  flex-shrink: 0;
  block-size: 16px;
  inline-size: 16px;
  padding-inline-end: var(--space-2);
}

.grouped-links__link span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.grouped-links__link:hover span {
  text-decoration: underline;
}

.search {
  position: relative;
}

.search__input {
  padding-block: var(--space-2);
  padding-inline: var(--space-5) var(--space-7);
  background-color: var(--color-neutral-bg);
  color: var(--color-neutral-text-contrast);
  border: none;
  inline-size: 230px;
  position: relative;
}

.search__input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.search__input:hover {
  background-color: var(--color-neutral-bg-hover);
}

.search__input:focus-visible {
  outline: var(--color-primary-focus-ring) solid 2px;
}

.search__input::placeholder {
  color: var(--color-neutral-placeholder);
}

.search[data-mode="overlay"] .search__input {
  border-radius: var(--rounded-full);
}

.search[data-mode="inline"] .search__input {
  padding-block: var(--space-3);
  padding-inline: var(--space-4);
  border-radius: var(--rounded-md);
  inline-size: 100%;
  display: block;
}

.search__kbd {
  pointer-events: none;
  position: absolute;
  inset-block-start: calc(50% - 1px);
  inset-inline-end: var(--space-4);
  transform: translateY(-50%);
}

input:hover + .search__kbd, input:focus + .search__kbd {
  transform: translateY(calc(1px - 50%));
}

.search[data-mode="overlay"] .search__popover {
  z-index: 1;
  box-shadow: var(--shadow-lg);
  border-radius: var(--rounded-lg);
  background-color: var(--color-surface);
  max-block-size: 300px;
  inline-size: 400px;
  position: absolute;
  inset-block-start: calc(100% + 4px);
  inset-inline-end: 0;
  overflow: auto;
}

.search[data-mode="inline"] .search__popover {
  margin-block-start: var(--space-6);
}

.search__info {
  color: var(--color-neutral-text);
}

.search[data-mode="overlay"] .search__info {
  padding-inline: var(--space-4);
  padding-block: var(--space-3);
  font-size: var(--font-size-sm);
}

.search[data-mode="inline"] .search__results li + li {
  margin-block-start: var(--space-2);
}

.search__result {
  gap: var(--space-1) var(--space-4);
  padding: var(--space-4);
  color: var(--color-primary-text);
  grid-template-columns: minmax(0, 1fr) auto;
  text-decoration: none;
  display: grid;
}

.search__popover:hover .search__result {
  transition: all .2s;
}

.search__result:hover, .search__result[aria-selected="true"] {
  background-color: var(--color-primary-bg-subtle);
}

.search[data-mode="inline"] .search__result {
  border-radius: var(--rounded-lg);
}

.search[data-mode="overlay"] .search__result:not([data-first="true"]) {
  border-block-start: 1px solid var(--color-primary-line-weak);
}

.search__note-title {
  font-weight: var(--font-weight-medium);
}

.search__note-content {
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
}

.search__note-content mark {
  background: var(--color-primary-mark);
  color: inherit;
}

.search__selected-icon {
  opacity: 0;
  grid-area: 1 / 2 / span 2;
  align-self: end;
}

[aria-selected="true"] .search__selected-icon {
  opacity: 1;
}

.nav-group[data-expanded] + .nav-group {
  margin-block-start: var(--space-6);
}

.nav-group:not([data-expanded]) + .nav-group {
  margin-block-start: var(--space-4);
}

.nav-group__subtitle, .nav-group__toggle {
  padding-block: 0;
  padding-inline: var(--space-2);
  font-size: var(--font-size-sm);
  color: var(--color-neutral-text);
  margin-block-end: var(--space-1);
}

.nav-group__toggle {
  cursor: pointer;
  background: none;
  border: 0;
  justify-content: space-between;
  align-items: center;
  inline-size: 100%;
  display: flex;
}

.nav-group__toggle[aria-expanded="true"] svg {
  transform: rotate(90deg);
}

.nav-list .nav-list {
  margin-block-start: 1px;
  margin-inline-start: var(--space-4);
}

.nav-list li li {
  margin-block-start: 1px;
}

.nav-list__link-with-toggle {
  grid-template-columns: minmax(0, 1fr);
  display: grid;
}

.nav-list__link-with-toggle > * {
  grid-area: 1 / 1;
}

.nav-list__toggle {
  appearance: none;
  cursor: pointer;
  background: none;
  border: 0;
  inline-size: 100%;
}

.nav-list__toggle:hover {
  background-color: var(--color-neutral-bg);
}

.nav-list__toggle svg {
  opacity: .75;
  margin-inline-start: auto;
}

.nav-list__toggle[aria-expanded="true"] svg {
  transform: rotate(90deg);
}

.nav-list__link {
  gap: var(--space-2);
  padding-block: var(--space-2);
  padding-inline: var(--space-2);
  border-radius: var(--rounded-md);
  color: var(--color-neutral-text);
  align-items: center;
  text-decoration: none;
  display: flex;
}

.nav-list__link + .nav-list__toggle {
  justify-self: end;
  inline-size: auto;
}

.nav-list__link:hover {
  background-color: var(--color-neutral-bg);
}

.nav-list__link[aria-current="page"] {
  background-color: var(--color-primary-bg);
}

.nav-list__link[aria-current="page"] + .nav-list__toggle:hover {
  background-color: var(--color-primary-bg-hover);
}

.nav-list__link span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nav-section {
  padding-inline: var(--space-4);
  margin-block-start: var(--space-7);
}

.nav-section:first-of-type, .nav-section.nav-section--continued {
  margin-block-start: var(--space-4);
}

.nav-section:last-of-type {
  margin-block-end: var(--space-4);
}

.nav-section__title {
  font-size: var(--font-size-sm);
  color: var(--color-neutral-text);
  text-transform: uppercase;
  letter-spacing: .04em;
  margin-block-end: var(--space-2);
  margin-inline-start: var(--space-2);
}

.tags-list {
  gap: var(--space-2);
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  align-items: center;
  display: grid;
}

.theme-switcher {
  border-radius: var(--rounded-full);
  background-color: var(--color-neutral-bg);
  justify-content: center;
  align-items: center;
  padding: 3px;
  display: flex;
}

.theme-switcher:has(input:focus-visible) {
  background-color: var(--color-neutral-bg-hover);
}

.theme-switcher__label {
  padding: var(--space-2);
  border-radius: var(--rounded-full);
  color: var(--color-neutral-text);
  cursor: pointer;
}

.theme-switcher__label:hover {
  color: var(--color-neutral-text-contrast);
  background-color: var(--color-neutral-bg-hover);
}

input:checked + .theme-switcher__label {
  box-shadow: var(--shadow-sm);
  color: var(--color-primary-text);
  background-color: var(--color-surface);
}

input:checked + .theme-switcher__label svg {
  fill: var(--color-primary-bg);
}

.toc__label {
  color: var(--color-neutral-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-bold);
  margin: 0;
}

.toc__item {
  margin-block: var(--space-1);
  margin-inline-start: calc((var(--level, 1)  - 1) * var(--space-4));
}

.toc__link {
  color: var(--color-primary-text);
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-normal);
  text-decoration: none;
}

.toc__link:hover {
  text-decoration: underline;
}

.skip-link {
  padding-block: var(--space-2);
  padding-inline: var(--space-5);
  box-shadow: var(--shadow-xxl);
  border-radius: var(--rounded-full);
  background-color: var(--color-surface);
  color: var(--color-primary-text-contrast);
  font-weight: var(--font-weight-bold);
  z-index: 4;
  text-decoration: none;
  position: fixed;
  inset-block-start: var(--space-4);
  inset-inline-start: var(--space-4);
  transform: translateX(-10000px);
}

.skip-link:focus {
  transform: translateX(0);
}

[x-cloak] {
  display: none !important;
}

@media (width >= 50em) {
  .hide-md {
    display: none !important;
  }
}

@media (width >= 64em) {
  .hide-lg {
    display: none !important;
  }
}

@media (width >= 75em) {
  .hide-xl {
    display: none !important;
  }
}

.inline {
  justify-content: flex-start;
  gap: var(--inline, var(--space-2));
  display: flex;
}

.show-md {
  display: none !important;
}

@media (width >= 50em) {
  .show-md {
    display: block !important;
  }
}

.show-lg {
  display: none !important;
}

@media (width >= 64em) {
  .show-lg {
    display: block !important;
  }
}

.show-xl {
  display: none !important;
}

@media (width >= 75em) {
  .show-xl {
    display: block !important;
  }
}

.stack > * {
  --stack: var(--space-2);
  margin-block: 0;
}

.stack > * + * {
  margin-block-start: var(--stack) !important;
}

.stack[data-stack="4"] > * {
  --stack: var(--space-4);
}

.stack[data-stack="6"] > * {
  --stack: var(--space-6);
}

.visually-hidden {
  white-space: nowrap;
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  border: 0 !important;
  block-size: 1px !important;
  inline-size: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  overflow: hidden !important;
}

/*# sourceMappingURL=app.iris.css.map */
